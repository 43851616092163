import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { kebabCase } from "lodash"
import localize from "../components/localize"
import "twin.macro"
import PortableText from "../components/portableText"
import Img from "gatsby-image"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    allSanityTalleres {
      edges {
        node {
          title {
            _type
            es
            pt
            en
          }
          id
          mainImage {
            asset {
              fluid(maxWidth: 500) {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          slug {
            current
          }
        }
      }
    }
  }
`

const TalleresPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        <body className="invert-nav" />
      </Helmet>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "workshop" })} />
      <div tw="pb-6 pt-40 bg-gray-200 mb-6 w-full " className="shadow-xl">
        <h1 tw="text-gray-900 font-serif text-center text-4xl">
          <FormattedMessage id="workshops" />
        </h1>
      </div>
      <div className="max-w-5xl mx-auto mt-2 ">
        <div className="grid gap-4 md:grid-cols-2">
          {data.allSanityTalleres.edges.map(({ node: article }) => (
            <div
              key={article.id}
              className="relative overflow-hidden bg-gray-100 rounded-md shadow-2xl"
            >
              {article.mainImage && (
                <Img
                  fluid={article.mainImage.asset.fluid}
                  className="w-full h-64 object-full"
                />
              )}
              <Link
                to={`/talleres/${kebabCase(article.slug.current)}`}
                className="px-6 leading-snug transition-all duration-500 ease-in-out transform"
                tw="text-3xl hover:opacity-50 inline-block no-underline pt-6 px-12 text-center font-serif"
              >
                <span className="text-gray-900">{article.title}</span>
              </Link>
              <div className="prose prose-xl ">
                <div className="max-w-lg px-6 mx-auto text-center text-gray-900 ">
                  {article._rawExcerpt && (
                    <PortableText blocks={article._rawExcerpt} />
                  )}
                </div>
                <Link
                  to={`/talleres/${kebabCase(article.slug.current)}`}
                  className="px-8 btn"
                  tw=""
                >
                  <FormattedMessage id="see_workshops" />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default localize(TalleresPage)
